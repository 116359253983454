// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-main-js": () => import("./../../../src/pages/main.js" /* webpackChunkName: "component---src-pages-main-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-how-it-works-js": () => import("./../../../src/templates/how-it-works.js" /* webpackChunkName: "component---src-templates-how-it-works-js" */),
  "component---src-templates-interior-js": () => import("./../../../src/templates/interior.js" /* webpackChunkName: "component---src-templates-interior-js" */),
  "component---src-templates-landing-page-js": () => import("./../../../src/templates/landing-page.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-lookbook-js": () => import("./../../../src/templates/lookbook.js" /* webpackChunkName: "component---src-templates-lookbook-js" */),
  "component---src-templates-membership-js": () => import("./../../../src/templates/membership.js" /* webpackChunkName: "component---src-templates-membership-js" */),
  "component---src-templates-services-js": () => import("./../../../src/templates/services.js" /* webpackChunkName: "component---src-templates-services-js" */),
  "component---src-templates-stylist-js": () => import("./../../../src/templates/stylist.js" /* webpackChunkName: "component---src-templates-stylist-js" */),
  "component---src-templates-stylists-js": () => import("./../../../src/templates/stylists.js" /* webpackChunkName: "component---src-templates-stylists-js" */)
}

