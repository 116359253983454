/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "./src/assets/css/base.scss"

export const onRouteUpdate = ({ location }) => {
  // wrap inside a timeout to ensure the title has properly been changed
  setTimeout(() => {
    window.dataLayer.push({ event: `gatsby-route-change` })
  }, 50)
}
